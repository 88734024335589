import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom"; // Import useLocation from react-router-dom
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import '../css/Navbar.css';

function Navbar() {
    const [click, setClick] = useState(false);
    const location = useLocation(); // Get current route location

    const handleClick = () => setClick(!click);

    // Modified closeMobileMenu to scroll to the top when a nav link is clicked
    const closeMobileMenu = () => {
        setClick(false);
        window.scrollTo(0, 0); // Scroll to the top of the page
    };

    // A function to check if the current route matches the link's path, handling dynamic pages
    const isActive = (path) => {
        // Check for exact match for the home route
        if (path === '/' && location.pathname === '/') {
            return true;
        }

        // Handle dynamic pages like `/interieurbouw/:location`
        if (path === '/' && location.pathname.startsWith('/interieurbouw')) {
            return true;
        }

        // Return true if the current path exactly matches the provided path
        return location.pathname === path;
    };

    return (
        <>
            <nav className='navbar'>
                <div className='navbar-container'>
                    <Link to='/' className='navbar-logo' onClick={closeMobileMenu}>
                        <img className='logoimg' src={require('../img/logo/logo.png')} alt='logo'/>
                    </Link>
                    <div className='menu-icon' onClick={handleClick}>
                        <FontAwesomeIcon icon={click ? faTimes : faBars}/>
                    </div>
                    <ul className={click ? 'nav-menu active' : 'nav-menu'}>
                        <li className='nav-item'>
                            <Link
                                to='/'
                                className={`nav-links ${isActive('/') ? 'active' : ''}`}
                                onClick={closeMobileMenu}
                            >
                                Home
                            </Link>
                        </li>
                        <li className='nav-item'>
                            <Link
                                to='/Realisaties'
                                className={`nav-links ${isActive('/Realisaties') ? 'active' : ''}`}
                                onClick={closeMobileMenu}
                            >
                                Portfolio
                            </Link>
                        </li>
                        <li className='nav-item'>
                            <Link
                                to='/Contact'
                                className={`nav-links ${isActive('/Contact') ? 'active' : ''}`}
                                onClick={closeMobileMenu}
                            >
                                Contact
                            </Link>
                        </li>
                    </ul>
                </div>
            </nav>
        </>
    );
}

export default Navbar;
