import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import photoData from './photoData'; // Your image source data
import '../css/Gallery.css'; // Your updated CSS file
import '../css/backAboveButton.css';

const Gallery = () => {
    const { type } = useParams();
    const photos = photoData[type] || [];
    const [showButton, setShowButton] = useState(false);
    const [showNotification, setShowNotification] = useState(false);
    const [notificationMessage, setNotificationMessage] = useState('Beweeg over de afbeeldingen voor info en klik om projectdetails te bekijken!'); // Default message

    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to the top when the component mounts

        // Check if the user has already seen the notification in this session
        const hasSeenNotification = sessionStorage.getItem('hasSeenGalleryNotification');
        if (!hasSeenNotification) {
            setShowNotification(true);
            // Hide the notification after 3 seconds
            setTimeout(() => {
                setShowNotification(false);
                sessionStorage.setItem('hasSeenGalleryNotification', 'true'); // Save the state in sessionStorage
            }, 3000);
        }

        // Update message based on screen size
        const updateMessage = () => {
            if (window.innerWidth <= 768) {
                setNotificationMessage('Swipe over de afbeeldingen voor meer info en tik om details te bekijken!'); // Message for small screens
            } else {
                setNotificationMessage('Beweeg over de afbeeldingen voor info en klik om projectdetails te bekijken!');
            }
        };

        // Initial check
        updateMessage();

        // Listen for resize events
        window.addEventListener('resize', updateMessage);

        const handleScroll = () => {
            if (window.scrollY > 20) {
                setShowButton(true);
            } else {
                setShowButton(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
            window.removeEventListener('resize', updateMessage);
        };
    }, []);

    const navigateToProject = (project) => {
        navigate(`/gallery/${type}/${project}`);
    };

    // Function to handle touch events
    const handleTouch = (event, index) => {
        const overlay = document.getElementById(`overlay-${index}`);
        if (overlay) {
            overlay.style.opacity = '1'; // Show the overlay when touched
        }

        // Hide overlay after a short delay to mimic hover effect
        setTimeout(() => {
            if (overlay) {
                overlay.style.opacity = '0';
            }
        }, 1500); // 1.5 seconds delay before hiding overlay
    };

    return (
        <div className="containerRest">
            {/* Notification Overlay */}
            {showNotification && (
                <div className="notification-overlay">
                    <div className="notification-content">
                        <p>{notificationMessage}</p>
                    </div>
                </div>
            )}

            <div className="gallery">
                {photos.map((project, index) => (
                    <div
                        key={index}
                        className="gallery-item"
                        onClick={() => navigateToProject(project.project)}
                        onTouchStart={(e) => handleTouch(e, index)} // Listen for touch events
                    >
                        <img src={project.images[0].src} alt={`${type} project`} />
                        <div id={`overlay-${index}`} className="overlay">
                            <div className="text">{project.description}</div>
                        </div>
                    </div>
                ))}
            </div>
            <div className="button-container1">
                <button onClick={() => window.history.back()}>Terug</button>
            </div>
            {showButton && (
                <button id="backToTopBtn" onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })} title="Go to top">↑</button>
            )}
        </div>
    );
};

export default Gallery;
