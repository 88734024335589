const photoData = {
    badkamers: [
        {
            project: 'BadkamerProject2',
            images: [
                {src: require('../img/gallery/badkamer/badkamer1Test.jpg'), description: ''},
            ],
            description: ''
        },
        {
            project: 'BadkamerProject1',
            images: [
                {src: require('../img/gallery/badkamer/badkamer1/badkamer1.jpg'), description: ''},
                {src: require('../img/gallery/badkamer/badkamer1/badkamer12.jpg'), description: ''},
                {src: require('../img/gallery/badkamer/badkamer1/badkamer11.jpg'), description: ''},
            ],
            description: 'Voor deze klant hebben we een badkamer gemaakt, inclusief aansluiting van leidingen en sanitair.'
        },
        {
            project: 'BadkamerProject3',
            images: [
                {src: require('../img/gallery/badkamer/badkamer2/badkamer1.jpg'), description: ''},
                {src: require('../img/gallery/badkamer/badkamer2/badkamer2.jpg'), description: ''},
                {src: require('../img/gallery/badkamer/badkamer2/badkamer3.jpg'), description: ''},
            ],
            description: 'Voor deze klant hebben we zijn oude badkamer met bad vervangen door een nieuwe met douche, elektrische verwarming en wc.'
        },
        {
            project: 'BadkamerProject6',
            images: [

                {src: require('../img/gallery/badkamer/badkamer5/badkamer1.jpg'), description: ''},
                {src: require('../img/gallery/badkamer/badkamer5/badkamer2.jpg'), description: ''},
                {src: require('../img/gallery/badkamer/badkamer5/badkamer3.jpg'), description: ''},
            ],
            description: 'Voor deze klant hebben we een volledige badkamer gebouwd inclusief sanitair en leidingen.'
        },
        {
            project: 'BadkamerProject4',
            images: [
                {src: require('../img/gallery/badkamer/badkamer3/badkamer1Test.jpg'), description: ''},
            ], description: 'Voor deze klant hebben we een badkamermeubel inclusief lavabo gemaakt.'
        },

        {
            project: 'BadkamerProject5',
            images: [

                {src: require('../img/gallery/badkamer/badkamer4/badkamer1.jpg'), description: ''},
                {src: require('../img/gallery/badkamer/badkamer4/badkamer2.jpg'), description: ''},
                {src: require('../img/gallery/badkamer/badkamer4/badkamer3.jpg'), description: ''},
            ],
            description: 'Voor deze klant hebben we het bad in zijn badkamer verwijderd en op dezelfde plaats een douche geplaatst'
        },
        {
            project: 'BadkamerProject7',
            images: [
                {src: require('../img/gallery/badkamer/badkamer6/badkamer1.jpg'), description: ''},
                {src: require('../img/gallery/badkamer/badkamer6/badkamer2.jpg'), description: ''},
                {src: require('../img/gallery/badkamer/badkamer6/badkamer3.jpg'), description: ''},
                {src: require('../img/gallery/badkamer/badkamer6/badkamer4.jpg'), description: ''},
                {src: require('../img/gallery/badkamer/badkamer6/badkamer5.jpg'), description: ''},
                {src: require('../img/gallery/badkamer/badkamer6/badkamer7.jpg'), description: ''},
                {src: require('../img/gallery/badkamer/badkamer6/badkamer8.jpg'), description: ''},
                {src: require('../img/gallery/badkamer/badkamer6/badkamer9.jpg'), description: ''},
                {src: require('../img/gallery/badkamer/badkamer6/badkamer10.jpg'), description: ''},
                {src: require('../img/gallery/badkamer/badkamer6/badkamer11.jpg'), description: ''},
                {src: require('../img/gallery/badkamer/badkamer6/badkamer12.jpg'), description: ''},
            ],
            description: 'Voor deze klant hebben we een badkamer volledig opgebouwd met hoogwaardige materialen, inclusief plaatsing van sanitair en leidingen. Hieronder kan je het proces vanaf de opbouw bekijken.'
        },
        {
            project: 'BadkamerProject8',
            images: [

                {src: require('../img/gallery/badkamer/badkamer7/badkamer1.jpg'), description: ''},
                {src: require('../img/gallery/badkamer/badkamer7/badkamer2.jpg'), description: ''},
                {src: require('../img/gallery/badkamer/badkamer7/badkamer3.jpg'), description: ''},
            ],
            description: 'Voor deze klant hebben we een volledige badkamer op maat gemaakt met ook plaatsing van de douche, kasten en het installeren van sanitair en elektriciteit'
        },
        {
            project: 'BadkamerProject9',
            images: [

                {src: require('../img/gallery/badkamer/badkamer8/badkamer1.jpg'), description: ''},
                {src: require('../img/gallery/badkamer/badkamer8/badkamer2.jpg'), description: ''},
                {src: require('../img/gallery/badkamer/badkamer8/badkamer3.jpg'), description: ''},
                {src: require('../img/gallery/badkamer/badkamer8/badkamer4.jpg'), description: ''},
            ],
            description: 'Bij deze klant hebben we het bad geïnstalleerd met behulp van een zelfgemaakt frame. De tegels werden geplaatst in samenwerking met een partnerbedrijf.'
        },
    ],
    keukens: [
        {
            project: 'KeukenProject1',
            images: [
                {src: require('../img/gallery/keuken/keuken1/keuken1.jpg'), description: ''},
                {src: require('../img/gallery/keuken/keuken1/keuken2.jpg'), description: ''},
                {src: require('../img/gallery/keuken/keuken1/keuken3.jpg'), description: ''},
            ],
            description: ''
        },
        {
            project: 'KeukenProject2',
            images: [
                {src: require('../img/gallery/keuken/keuken2/keuken1.jpg'), description: ''},
                {src: require('../img/gallery/keuken/keuken2/keuken2.jpg'), description: ''},
                {src: require('../img/gallery/keuken/keuken2/keuken3.jpg'), description: ''},
                {src: require('../img/gallery/keuken/keuken2/keuken4.jpg'), description: ''},
                {src: require('../img/gallery/keuken/keuken2/keuken7.jpg'), description: ''},
                {src: require('../img/gallery/keuken/keuken2/keuken1.jpg'), description: ''},
                {src: require('../img/gallery/keuken/keuken2/keuken5.jpg'), description: ''},
                {src: require('../img/gallery/keuken/keuken2/keuken6.jpg'), description: ''},
            ], description: ''
        },
        {
            project: 'KeukenProject3',
            images: [
                {src: require('../img/gallery/keuken/keuken3/keuken1.jpg'), description: ''},
                {src: require('../img/gallery/keuken/keuken3/keuken2.jpg'), description: ''},
                {src: require('../img/gallery/keuken/keuken3/keuken3.jpg'), description: ''},
                {src: require('../img/gallery/keuken/keuken3/keuken4.jpg'), description: ''},
                {src: require('../img/gallery/keuken/keuken3/keuken5.jpg'), description: ''},
                {src: require('../img/gallery/keuken/keuken3/keuken6.jpg'), description: ''},
                {src: require('../img/gallery/keuken/keuken3/keuken7.jpg'), description: ''},
            ],
            description: 'Voor deze klant hebben we een volledige nieuwe keuken op maat geïnstalleerd inclusief eiken achterwand, inbouw van toestellen, leidingen en elektriciteit.'
        },
        {
            project: 'KeukenProject4',
            images: [
                {src: require('../img/gallery/keuken/keuken4/keuken1.jpg'), description: ''},
                {src: require('../img/gallery/keuken/keuken4/keuken2.jpg'), description: ''},
                {src: require('../img/gallery/keuken/keuken4/keuken3.jpg'), description: ''},
                {src: require('../img/gallery/keuken/keuken4/keuken4.jpg'), description: ''},
                {src: require('../img/gallery/keuken/keuken4/keuken5.jpg'), description: ''},
                {src: require('../img/gallery/keuken/keuken4/keuken6.jpg'), description: ''},
                {src: require('../img/gallery/keuken/keuken4/keuken7.jpg'), description: ''},
            ],
            description: 'Voor deze klant hebben we een volledige nieuwe keuken op maat geïnstalleerd inclusief inbouw van toestellen, leidingen en elektriciteit.'
        },
        {
            project: 'KeukenProject5',
            images: [
                {src: require('../img/gallery/keuken/keuken5/keuken1.jpg'), description: ''},
                {src: require('../img/gallery/keuken/keuken5/keuken2.jpg'), description: ''},
                {src: require('../img/gallery/keuken/keuken5/keuken3.jpg'), description: ''},
            ],
            description: 'Voor een appartementsgebouw hebben we de volledige keukens geïnstalleerd, inclusief inbouwtoestellen, leidingen en elektriciteit.'
        },
        {
            project: 'KeukenProject6',
            images: [
                {src: require('../img/gallery/keuken/keuken6/keuken1.jpg'), description: ''},
                {src: require('../img/gallery/keuken/keuken6/keuken2.jpg'), description: ''},
                {src: require('../img/gallery/keuken/keuken6/keuken3.jpg'), description: ''},
                {src: require('../img/gallery/keuken/keuken6/keuken4.jpg'), description: ''},
                {src: require('../img/gallery/keuken/keuken6/keuken5.jpg'), description: ''},
                {src: require('../img/gallery/keuken/keuken6/keuken6.jpg'), description: ''},

            ],
            description: 'Voor deze klant hebben we een keuken met achterwand gemaakt, inclusief een geïntegreerde zijdeur naar berging. De kasten zijn gemaakt met een verdoken greep. We zorgden ook voor de inbouw van alle toestellen + het aansluiten van alle leidingen en elektriciteit.'
        },
        {
            project: 'KeukenProject7',
            images: [
                {src: require('../img/gallery/keuken/keuken7/keuken1.jpg'), description: ''},
                {src: require('../img/gallery/keuken/keuken7/keuken2.jpg'), description: ''},
                {src: require('../img/gallery/keuken/keuken7/keuken3.jpg'), description: ''},
                {src: require('../img/gallery/keuken/keuken7/keuken4.jpg'), description: ''},
                {src: require('../img/gallery/keuken/keuken7/keuken5.jpg'), description: ''},
                {src: require('../img/gallery/keuken/keuken7/keuken6.jpg'), description: ''},
            ],
            description: 'Voor deze klant hebben we een volledige keuken gemaakt in cottage stijl met aansluiting van de toestellen en leidingen.'
        },
        {
            project: 'KeukenProject8',
            images: [
                {src: require('../img/gallery/keuken/keuken8/keuken1.jpg'), description: ''},
            ],
            description: 'Voor deze klant hebben we een keuken gemaakt met kookeiland en ingebouwde keukentoestellen. Ver-di interieurbouw zorgde ook voor de aansluiting van de electro en nutsvoorzieningen.'
        }
    ],
    maatwerk: [
        {
            project: 'MaatwerkProject1',
            images: [
                {src: require('../img/gallery/maatwerk/maatwerk1/maatwerk1.jpg'), description: ''},
                {src: require('../img/gallery/maatwerk/maatwerk1/maatwerk2.jpg'), description: ''},
                {src: require('../img/gallery/maatwerk/maatwerk1/maatwerk3.jpg'), description: ''},
                {src: require('../img/gallery/maatwerk/maatwerk1/maatwerk4.jpg'), description: ''},
                {src: require('../img/gallery/maatwerk/maatwerk1/maatwerk5.jpg'), description: ''},
                {src: require('../img/gallery/maatwerk/maatwerk1/maatwerk6.jpg'), description: ''},
                {src: require('../img/gallery/maatwerk/maatwerk1/maatwerk7.jpg'), description: ''},
            ], description: ''
        },
        {
            project: 'MaatwerkProject2',
            images: [
                {src: require('../img/gallery/maatwerk/maatwerk2/maatwerk1.jpg'), description: ''},
                {src: require('../img/gallery/maatwerk/maatwerk2/maatwerk2.jpg'), description: ''},
            ], description: 'Voor deze klant hebben we een salontafel op maat gemaakt.'
        },
        {
            project: 'MaatwerkProject3',
            images: [
                {src: require('../img/gallery/maatwerk/maatwerk3/maatwerk1.jpg'), description: ''},
                {src: require('../img/gallery/maatwerk/maatwerk3/maatwerk3.jpg'), description: ''},
                {src: require('../img/gallery/maatwerk/maatwerk3/maatwerk4.jpg'), description: ''},
            ], description: 'Voor deze klant hebben we een racebed gemaakt inclusief werkende verlichting.'
        },
        {
            project: 'MaatwerkProject6',
            images: [
                {src: require('../img/gallery/maatwerk/maatwerk6/maatwerk1.jpg'), description: ''},
            ], description: ''
        },
        {
            project: 'MaatwerkProject',
            images: [
                {src: require('../img/gallery/maatwerk/maatwerk5/maatwerk1.jpg'), description: ''},
                {src: require('../img/gallery/maatwerk/maatwerk5/maatwerk2.jpg'), description: ''},
            ], description: 'Voor deze klant hebben we een halkast gemaakt.'
        },
        {
            project: 'MaatwerkProject4',
            images: [
                {src: require('../img/gallery/maatwerk/maatwerk4/maatwerk2.jpg'), description: ''},
                {src: require('../img/gallery/maatwerk/maatwerk4/maatwerk1.jpg'), description: ''},
                {src: require('../img/gallery/maatwerk/maatwerk4/maatwerk3.jpg'), description: ''},
                {src: require('../img/gallery/maatwerk/maatwerk4/maatwerk4.jpg'), description: ''},
            ], description: 'Voor deze klant hebben we een dressing op maat gemaakt.'
        },
        {
            project: 'MaatwerkProject7',
            images: [
                {src: require('../img/gallery/maatwerk/maatwerk7/maatwerk1.jpg'), description: ''},
            ], description: ''
        }
    ]
};

export default photoData;
