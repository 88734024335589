import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../css/Realisaties.css';
import SEO from "../components/SEO";
import photoData from '../components/photoData'; // Import dynamic photo data

const Realisaties = () => {
    const navigate = useNavigate();

    // Map over types in photoData and dynamically select the first image from each type
    const galleries = Object.keys(photoData).map((type) => {
        const firstProject = photoData[type][0]; // Get the first project in the category
        const firstImage = firstProject.images[0]; // Get the first image of the project

        return {
            title: type.charAt(0).toUpperCase() + type.slice(1), // Capitalize type
            image: firstImage.src, // Use the src of the first image
            type: type,
        };
    });

    const navigateToGallery = (type) => {
        navigate(`/gallery/${type}`);
    };

    const realisatiesSchemaMarkup = {
        "@context": "https://schema.org",
        "@type": "LocalBusiness",
        "name": "Ver-di Interieurbouw",
        "description": "Neem contact op met Ver-di Interieurbouw in Sint-Niklaas voor vragen of andere informatie. We staan altijd klaar om u te helpen.",
        "url": "https://ver-di-interieurbouw.be/Realisaties",
        "address": {
            "@type": "PostalAddress",
            "streetAddress": "Houten Schoen 122",
            "addressLocality": "Sint-Niklaas",
            "addressRegion": "Oost-Vlaanderen",
            "postalCode": "9100",
            "addressCountry": "BE"
        },
        "contactPoint": {
            "@type": "ContactPoint",
            "telephone": "+32 478 98 30 24",
            "contactType": "Customer Service",
            "areaServed": "BE",
            "availableLanguage": "Dutch"
        }
    };

    return (
        <div>
            <SEO
                title="Realisaties | Ver-di Interieurbouw"
                description="Realisaties van Ver-di Interieurbouw uw vakman in Sint-Niklaas."
                keywords="realisaties, Ver-Di Interieurbouw, Maatwerk interieurs, Keukens op maat, Badkamers op maat, Maatwerk meubels, Keukenrenovatie, Badkamerrenovatie, Interieur op maat, Maatwerk interieurdesign, Op maat gemaakte meubels, Interieurbouwer, Keukenontwerp, Badkamerontwerp, Persoonlijk interieuradvies, Vakmanschap interieur, Interieur op maat laten maken"
                canonical="https://ver-di-interieurbouw.be/Realisaties"
                schemaMarkup={realisatiesSchemaMarkup}
            />
            <div className="realisaties-container">
                {galleries.map((gallery) => (
                    <div key={gallery.type} className="realisaties-item"
                         onClick={() => navigateToGallery(gallery.type)}>
                        <img src={gallery.image} alt={gallery.title} className="realisaties-image"/>
                        <div className="realisaties-title">{gallery.title}</div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Realisaties;
