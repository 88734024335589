import React from "react";
import '../css/Home.css'
import ContactComponent from "../components/ContactComponent";
import SEO from "../components/SEO";

function Contact() {
    const contactSchemaMarkup = {
        "@context": "https://schema.org",
        "@type": "LocalBusiness",
        "name": "Ver-di Interieurbouw",
        "description": "Neem contact op met Ver-di Interieurbouw in Sint-Niklaas voor vragen of andere informatie. We staan altijd klaar om u te helpen.",
        "url": "https://ver-di-interieurbouw.be/Contact",
        "address": {
            "@type": "PostalAddress",
            "streetAddress": "Houten Schoen 122",
            "addressLocality": "Sint-Niklaas",
            "addressRegion": "Oost-Vlaanderen",
            "postalCode": "9100",
            "addressCountry": "BE"
        },
        "contactPoint": {
            "@type": "ContactPoint",
            "telephone": "+32 478 98 30 24",
            "contactType": "Customer Service",
            "areaServed": "BE",
            "availableLanguage": "Dutch"
        }
    };
    return (
        <>
            <SEO
                title="Contact | Ver-di Interieurbouw"
                description="Neem contact op met Ver-di Interieurbouw in Sint-Niklaas voor vragen of andere informatie. We staan altijd klaar om u te helpen."
                keywords="Contact, Ver-Di Interieurbouw, Maatwerk interieurs, Keukens op maat, Badkamers op maat, Maatwerk meubels, Keukenrenovatie, Badkamerrenovatie, Interieur op maat, Maatwerk interieurdesign, Op maat gemaakte meubels, Interieurbouwer, Keukenontwerp, Badkamerontwerp, Persoonlijk interieuradvies, Vakmanschap interieur, Interieur op maat laten maken"
                canonical="https://ver-di-interieurbouw.be/Contact"
                schemaMarkup={contactSchemaMarkup}
            />
            <div><ContactComponent/></div>
        </>
    );
}

export default Contact;