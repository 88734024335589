import './App.css';
import Navbar from "./components/Navbar";
import {BrowserRouter as Router, Routes, Route} from "react-router-dom";
import React from "react";
import Home from "./pages/Home";
import Contact from "./pages/Contact";
import FooterComponent from "./components/FooterComponent";
import Realisaties from "./pages/Realisaties";
import Gallery from "./components/Gallery";
import NoPage from "./pages/NoPage"
import DynamicPage from "./components/dynamic/DynamicPage";
import ProjectGallery from "./components/ProjectGallery";


const App = () => {
    return (
        <Router>
            <Navbar/>
            <Routes>
                <Route path="/" element={<Home/>}/>
                <Route path="/Realisaties" element={<Realisaties/>}/>
                <Route path="/gallery/:type" element={<Gallery/>}/>
                <Route path="/gallery/:type/:project" element={<ProjectGallery />} />
                <Route path="/Contact" element={<Contact/>}/>
                <Route path="/interieurbouw/:location" element={<DynamicPage />} />
                <Route path="*" element={<NoPage />} /> {/* Updated to use element instead of component */}
            </Routes>
            <FooterComponent/>
        </Router>
    );
};


export default App;
