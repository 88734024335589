import React, { useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope, faMapMarkerAlt, faClock } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import '../css/ContactComponent.css';

const ContactComponent = () => {
    const [isPopupVisible, setIsPopupVisible] = useState(false); // State to control the pop-up
    const [isLoading, setIsLoading] = useState(false); // State to control the loading spinner
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        subject: '',
        message: ''
    });

    const [errors, setErrors] = useState({}); // State to store form validation errors

    const [statusMessage, setStatusMessage] = useState(''); // Status message for submission
    const [errorMessage, setErrorMessage] = useState(''); // Error message

    const openingHoursRef = useRef(null);
    const googleMapRef = useRef(null);

    useEffect(() => {
        const targets = [openingHoursRef.current, googleMapRef.current];

        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('visible');
                }
            });
        }, { threshold: 0.1 });

        targets.forEach(target => {
            if (target) {
                observer.observe(target);
            }
        });

        return () => {
            targets.forEach(target => {
                if (target) observer.unobserve(target);
            });
        };
    }, []); // Empty dependency array ensures this runs only on mount/unmount

    // Handle input change
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };

    // Validate form fields
    const validateForm = () => {
        const newErrors = {};
        const name = formData.name.trim();
        const message = formData.message.trim();
        const subject = formData.subject.trim();

        if (!name) {
            newErrors.name = 'Naam is verplicht.';
        }

        if (!subject) {
            newErrors.subject = 'Onderwerp is verplicht.';  // Validate subject field
        }

        if (!message) {
            newErrors.message = 'Bericht is verplicht.';
        } else if (message.length > 500) {
            newErrors.message = 'Bericht mag niet langer zijn dan 500 karakters.';
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0; // Return true if no errors
    };

    // Handle form submission
    const handleSubmit = (e) => {
        e.preventDefault();
        setStatusMessage('');
        setErrorMessage('');

        // Validate form before submitting
        if (!validateForm()) return;

        setIsLoading(true); // Show loading spinner in the button

        // Prepare form data for sending to PHP backend
        const formParams = new URLSearchParams();
        formParams.append('name', formData.name);
        formParams.append('email', formData.email);
        formParams.append('subject', formData.subject);
        formParams.append('message', formData.message);

        // Send form data to PHP backend via axios
        axios.post('https://ver-di-interieurbouw.be/send-email.php', formParams, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            }
        })
            .then((response) => {
                setIsLoading(false); // Hide loading spinner
                console.log('Full Response:', response); // Log response for debugging
                // Check if the response status is 'success'
                if (response.data.status === 'success') {
                    setStatusMessage('Uw bericht is succesvol verzonden!');
                    setIsPopupVisible(true); // Show the pop-up on success
                } else {
                    setErrorMessage(response.data.message || 'Failed to send your message. Please try again.');
                }
            })
            .catch((error) => {
                setIsLoading(false); // Hide loading spinner on error
                console.error('Error:', error); // Log the error for debugging
                setErrorMessage('Something went wrong. Please try again.');
            });
    };

    // Close the pop-up and clear the form
    const closePopup = () => {
        setIsPopupVisible(false); // Hide the pop-up
        // Clear the form fields
        setFormData({
            name: '',
            email: '',
            subject: '',
            message: ''
        });
        setErrors({});
        setStatusMessage(''); // Clear the status message when the popup is closed
    };

    return (
        <div className="contact-page">
            {/* Upper Section: Contact Information and Form */}
            <div className="contact-upper-section">
                {/* Left: Contact Information */}
                <div className="contact-info">
                    <h2 className="titleContactOns">Contacteer ons</h2>
                    <ul>
                        <li className="contact-item">
                            <span><FontAwesomeIcon icon={faPhone} className="icon" /></span>
                            <div>
                                <h3>Bel ons</h3>
                                <p><a href="tel:+32478983024">+32 478 98 30 24</a></p>
                            </div>
                        </li>
                        <li className="contact-item">
                            <span><FontAwesomeIcon icon={faEnvelope} className="icon" /></span>
                            <div>
                                <h3>Email ons</h3>
                                <p><a href="mailto:ver-di@telenet.be">ver-di@telenet.be</a></p>
                            </div>
                        </li>
                        <li className="contact-item">
                            <span><FontAwesomeIcon icon={faMapMarkerAlt} className="icon" /></span>
                            <div>
                                <h3>Bezoek ons</h3>
                                <p>
                                    <a href="https://www.google.com/maps/search/?api=1&query=Houten+Schoen+122,+9100+Sint-Niklaas"
                                       target="_blank"
                                       rel="noopener noreferrer">
                                        Houten Schoen 122, 9100 Sint-Niklaas
                                    </a>
                                </p>
                            </div>
                        </li>
                    </ul>
                </div>

                {/* Right: Contact Form */}
                <div className="contact-form">
                    <h2>Laat ons een bericht</h2>
                    <form onSubmit={handleSubmit}>
                        <label>
                            Naam *
                            <input
                                type="text"
                                name="name"
                                value={formData.name}
                                onChange={handleInputChange}
                                placeholder="Enter naam"
                                required
                            />
                            {errors.name && <p className="error">{errors.name}</p>}
                        </label>
                        <label>
                            Email *
                            <input
                                type="email"
                                name="email"
                                value={formData.email}
                                onChange={handleInputChange}
                                placeholder="Enter email"
                                required
                            />
                        </label>
                        <label>
                            Onderwerp *
                            <input
                                type="text"
                                name="subject"
                                value={formData.subject}
                                onChange={handleInputChange}
                                placeholder="Enter onderwerp"
                                required
                            />
                            {errors.subject && <p className="error">{errors.subject}</p>}
                        </label>
                        <label>
                            Bericht *
                            <textarea
                                name="message"
                                value={formData.message}
                                onChange={handleInputChange}
                                placeholder="Enter bericht"
                                required
                                maxLength="500" // Limit the message length
                            ></textarea>
                            {errors.message && <p className="error">{errors.message}</p>}
                        </label>
                        <button
                            type="submit"
                            className={`contact-submit-button ${isLoading ? 'button-disabled' : ''}`}
                            disabled={isLoading} // Disable button during submission
                        >
                            {isLoading ? (
                                <div className="button-loading">
                                    <div className="button-spinner"></div>
                                    <span>Bezig met verzenden...</span>
                                </div>
                            ) : (
                                'Stuur Bericht'
                            )}
                        </button>
                    </form>
                    {errorMessage && <p className="error-message">{errorMessage}</p>}  {/* Display error messages */ }
                </div>
            </div>

            {/* Lower Section: Google Maps and Opening Hours */}
            <div className="contact-lower-section">
                {/* Left: Opening Hours */}
                <div className="opening-hours" ref={openingHoursRef}>
                    <h2><FontAwesomeIcon icon={faClock} className="icon" /> Openingstijden</h2>
                    <ul>
                        <li><span>Maandag</span><span>09:00 - 17:00</span></li>
                        <li><span>Dinsdag</span><span>09:00 - 17:00</span></li>
                        <li><span>Woensdag</span><span>09:00 - 17:00</span></li>
                        <li><span>Donderdag</span><span>09:00 - 17:00</span></li>
                        <li><span>Vrijdag</span><span>09:00 - 17:00</span></li>
                    </ul>
                </div>

                {/* Right: Google Maps */}
                <div className="google-map" ref={googleMapRef}>
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2521.5477259563767!2d4.131065515743968!3d51.15094797957786!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c39e57c2d7f7d1%3A0x738f9942e961f142!2sHouten%20Schoen%20122%2C%209100%20Sint-Niklaas%2C%20Belgium!5e0!3m2!1sen!2sus!4v1694444444444"
                        width="100%"
                        height="100%"
                        style={{ border: 0 }}
                        allowFullScreen=""
                        loading="lazy"
                        title="Google Maps"
                    ></iframe>
                </div>
            </div>

            {/* Pop-up Confirmation */}
            {isPopupVisible && (
                <div className="popup-overlay">
                    <div className="popup-content">
                        <h2>Bericht verzonden!</h2>
                        <p>{statusMessage}</p>
                        <button onClick={closePopup}>Sluit</button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ContactComponent;
