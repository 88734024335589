import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import photoData from './photoData';
import '../css/ProjectGallery.css';
import '../css/backAboveButton.css';

const ProjectGallery = () => {
    const { type, project } = useParams();
    const [showButton, setShowButton] = useState(false);
    useNavigate();
    const selectedProject = photoData[type].find(proj => proj.project === project);

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to the top when the component mounts

        const handleScroll = () => {
            if (window.scrollY > 20) {
                setShowButton(true);
            } else {
                setShowButton(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    if (!selectedProject) {
        return <div>Project not found</div>;
    }

    return (
        <div className="project-gallery-container">
            {/* Project Description */}
            <h2 className="project-description">{selectedProject.description}</h2>

            {/* Images displayed in a grid with 3 columns */}
            <div className="project-gallery">
                {selectedProject.images.map((image, index) => (
                    <div key={index} className="project-gallery-item">
                        <img src={image.src} alt={`${project} ${index + 1}`} />
                        <h3>{image.description}</h3>
                    </div>
                ))}
            </div>

            {/* Back Button */}
            <button className="back-button" onClick={() => window.history.back()}>Terug</button>
            {showButton && (
                <button id="backToTopBtn" onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })} title="Go to top">↑</button>
            )}
        </div>
    );
};

export default ProjectGallery;
