import React from 'react';

const NoPage = () => {
    return (
        <div>
            <h2>Oops, looks like the page is lost</h2>
            <p>This is not a fault, just an accident that was not intentional.</p>
        </div>
    );
}

export default NoPage;